import { DataService } from './dataService';
import { API } from '../api/index';

/**
 * Admin Login API
 */
export const loginAPI = requestBody => {
  return DataService.post(API.user.login, requestBody);
};

/**
 * Get Users
 */
export const getAllUsers = () => {
  return DataService.get(API.user.getUsers);
};

export const createUser = requestBody => {
  return DataService.post(API.user.createUser, requestBody);
};

export const getUser = requestBody => {
  return DataService.get(`${API.user.getUser}/${requestBody}`);
};

export const updateUser = requestBody => {
  return DataService.post(API.user.updateUser, requestBody);
};

export const deleteUser = requestBody => {
  return DataService.post(API.user.deleteUser, { user_id: requestBody });
};

export const getPassword = requestBody => {
  return DataService.post(API.user.getPassword, requestBody);
};

export const CreditOrDebitFund = requestBody => {
  return DataService.post(API.user.creditOrDebitFunds, requestBody);
};

export const getWalletHistory = requestBody => {
  return DataService.get(`${API.user.walletHistory}/${requestBody}`);
};

export const getDownloadRequestList = requestBody => {
  if (requestBody.start) {
    return DataService.get(`${API.user.downloadRequestList}?start=${requestBody.start}&end=${requestBody.end}`);
  } else {
    return DataService.get(`${API.user.downloadRequestList}`);
  }
};

export const getTransactionList = requestBody => {
  return DataService.get(`${API.user.transactionList}/${requestBody}`);
};

export const getPlanList = requestBody => {
  return DataService.get(`${API.plans.plans}`);
};

export const updatePlan = requestBody => {
  return DataService.post(API.plans.update, requestBody);
};

export const updateAdminEmail = requestBody => {
  return DataService.post(API.admin.updateAdmin, requestBody);
};

export const changePassword = requestBody => {
  return DataService.post(API.admin.changePassword, requestBody);
};

export const getCounts = requestBody => {
  return DataService.get(`${API.dashboard.getCounts}/${requestBody}`);
};
export const getAllClient = () => {
  return DataService.get(API.client.client);
};
export const clientFilter = requestBody => {
  return DataService.post(API.client.clientFilter, requestBody);
};
export const deleteClient = requestBody => {
  return DataService.post(API.client.deleteClient, { client_id: requestBody });
};
