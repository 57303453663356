import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      <Menu.Item icon={!topMenu && <FeatherIcon icon="home" />} key="dashboard" title="Dashboard">
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          Dashboard
        </NavLink>
      </Menu.Item>
      <Menu.Item icon={!topMenu && <FeatherIcon icon="users" />} key="users" title="Users">
        <NavLink onClick={toggleCollapsed} to={`${path}/users`}>
          Users
        </NavLink>
      </Menu.Item>
      <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="client" title="Client">
        <NavLink onClick={toggleCollapsed} to={`${path}/client`}>
          Client
        </NavLink>
      </Menu.Item>
      {/*<Menu.Item icon={!topMenu && <FeatherIcon icon="download" />} key="transaction" title="Transaction">
        <NavLink onClick={toggleCollapsed} to={`${path}/download-requests`}>
          Download Requests
        </NavLink>
      </Menu.Item>
      <Menu.Item icon={!topMenu && <FeatherIcon icon="gift" />} key="plans" title="Plans">
        <NavLink onClick={toggleCollapsed} to={`${path}/plans`}>
          Plans
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item icon={!topMenu && <FeatherIcon icon="users" />} key="transaction" title="Transaction">
        <NavLink onClick={toggleCollapsed} to={`${path}/users`}>
          Transactions
        </NavLink>
      </Menu.Item> */}
      {/* <Menu.Item icon={!topMenu && <FeatherIcon icon="file-text" />} key="posts" title="Posts">
        <NavLink onClick={toggleCollapsed} to={`${path}/posts`}>
          Posts
        </NavLink>
      </Menu.Item>
      <Menu.Item icon={!topMenu && <FeatherIcon icon="list" />} key="gyms" title="Gyms">
        <NavLink onClick={toggleCollapsed} to={`${path}/gyms`}>
          Gyms
        </NavLink>
      </Menu.Item>
      <Menu.Item icon={!topMenu && <FeatherIcon icon="gift" />} key="leader-board" title="Leader Board">
        <NavLink onClick={toggleCollapsed} to={`${path}/leader-board`}>
          Leader Board
        </NavLink>
      </Menu.Item> */}
      {/* <Menu.Item icon={!topMenu && <FeatherIcon icon="minus-circle" />} key="report-post" title="Report Post">
        <NavLink onClick={toggleCollapsed} to={`${path}/report-post`}>
          Reported Post
        </NavLink>
      </Menu.Item> */}
      <SubMenu key="charts" icon={!topMenu && <FeatherIcon icon="settings" />} title="Settings">
        <Menu.Item key="profile-change">
          <NavLink onClick={toggleCollapsed} to={`${path}/profile-change`}>
            Profile Setting
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="leaderboard-amount">
          <NavLink onClick={toggleCollapsed} to={`${path}/leaderboard_setting`}>
            Leaderboard Amount
          </NavLink>
        </Menu.Item> */}
        <Menu.Item key="app-version">
          <NavLink onClick={toggleCollapsed} to={`${path}/charts/peity`}>
            App Version
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="stripe-setting">
          <NavLink onClick={toggleCollapsed} to={`${path}/charts/peity`}>
            Stripe Setting
          </NavLink>
        </Menu.Item>
        <Menu.Item key="notification">
          <NavLink onClick={toggleCollapsed} to={`${path}/charts/peity`}>
            Notification
          </NavLink>
        </Menu.Item> */}
      </SubMenu>
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
